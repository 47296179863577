import axios from 'axios';

export default class TipoPadecimientoService {

	getTipoPadecimientos() {
		//return axios.get('assets/layout/data/tipopadecimientos.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/tipopadecimientos`;
		const url = `/api/tipopadecimientos`;
		return axios.get(url).then(res=>res.data.data);
		
	}
	
	createTipoPadecimientos(tipopadecimientos) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/tipopadecimientos`;
		const url = `/api/tipopadecimientos`;
		return axios.post(url,tipopadecimientos).then(res=>res.data.data);
	}
	
	updateTipoPadecimientos(tipopadecimientos) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/tipopadecimientos/${tipopadecimientos.id_tipo_padecimiento}`;
		const url = `/api/tipopadecimientos/${tipopadecimientos.id_tipo_padecimiento}`;
		return axios.put(url,tipopadecimientos).then(res=>res.data.data);
	}
	
	deleteTipoPadecimientos(tipopadecimientos) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/tipopadecimientos/${tipopadecimientos.id_tipo_padecimiento}`;
		const url = `/api/tipopadecimientos/${tipopadecimientos.id_tipo_padecimiento}`;
		return axios.delete(url).then(res=>res.data.data);
	}
	
	getTipoPadecimientosExport() {
		//return axios.get('assets/layout/data/tipopadecimientos.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/tipopadecimientosexport`;
		const url = `/api/tipopadecimientosexport`;
		return axios.get(url).then(res=>res.data.data);
		
	}
}