<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:left>
						<Button label="Nuevo Padecimiento..." icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" />
						<Button label="Exportar a Excel"  class="p-button-warning  p-mr-2" v-on:click="download" />
						<!-- <Button label="Eliminar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedPadecimientos || !selectedPadecimientos.length" /> -->
					</template>
				</Toolbar>

				<DataTable ref="dt" :value="padecimientos" v-model:selection="selectedPadecimientos" dataKey="id" :paginator="true" :rows="10" :filters="filters"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                            currentPageReportTemplate="Mostrando {first} al {last} de {totalRecords} padecimientos">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Catálogo de Padecimientos</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>

					<!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->
					<!-- <Column field="id_padecimiento" header="Id" :sortable="true"></Column> -->
                    <Column field="tipopadecimientos" header="Tipo" :sortable="true"></Column>
					<Column field="nombre" header="Padecimiento" :sortable="true"></Column>
                    <Column field="activo" header="Activo" :sortable="true">
						<template #body="slotProps">
                                    {{turno(slotProps.data.activo)}}
                        </template>
					</Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editPadecimiento(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeletePadecimiento(slotProps.data)" />
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="padecimientoDialog" :style="{width: '450px'}" header="Padecimiento" :modal="true" class="p-fluid">
					
					<div class="p-field">
						<label for="tipo">Tipo de Padecimiento *</label>
						<Dropdown id="tipo" name="tipo"  required="true"  v-model="padecimiento.id_tipo_padecimiento" :options="tipopadecimientos" optionLabel="nombre" placeholder="Selecionar tipo ..." autofocus :class="{'p-invalid': submitted && !padecimiento.id_tipo_padecimiento}" />
						<small class="p-invalid" v-if="submitted && !padecimiento.id_tipo_padecimiento">El tipo de dadecimiento es requerido</small>
					</div>

					<div class="p-field">
						<label for="nombre">Padecimiento *</label>
						<InputText id="nombre" v-model.trim="padecimiento.nombre" required="true" autofocus :class="{'p-invalid': submitted && !padecimiento.nombre}" />
						<small class="p-invalid" v-if="submitted && !padecimiento.nombre">El nombre de la padecimiento es requerido</small>
					</div>

                    <div class="p-field-checkbox">
                        <Checkbox id="activo" name="activo" :binary="true"  v-model="padecimiento.activo" />
                        <label for="activo">Activo</label>
                    </div>
                    <!--
					<div class="p-formgrid p-grid">
						<div class="p-field p-col">
							<label for="activo">Activo</label>                            
							<InputNumber id="activo" v-model="padecimiento.activo" integeronly />
						</div>
					</div>
                    -->
					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="savePadecimiento" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deletePadecimientoDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="padecimiento">¿Desea eliminar el padecimiento <b>{{padecimiento.nombre}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deletePadecimientoDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deletePadecimiento" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deletePadecimientosDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="padecimiento">¿Desea eliminar los padecimientos seleccionados?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deletePadecimientosDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteSelectedPadecimientos" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import PadecimientoService from '../service/PadecimientoService';
import TipoPadecimientoService from '../service/TipoPadecimientoService';
import XLSX from 'xlsx'

export default {
	data() {
		return {
			padecimientos: null,
			padecimientoDialog: false,
			deletePadecimientoDialog: false,
			deletePadecimientosDialog: false,
			padecimiento: {},
			selectedPadecimientos: null,
			filters: {},
			submitted: false,
			dropdownValues: [
				{nombre: 'Oncológicos', code: 'Oncológicos'},
				{nombre: 'Autoinmunes', code: 'Autoinmunes'},
				{nombre: 'Otros', code: 'Otros'}
			],
			tipopadecimientos: null,
			tipopadecimientosPost:[], 
			OptionTipoPadecimiento:null,
			PadecimientoExpor:null,
		}
	},
	padecimientoService: null,
	tipopadecimientoService: null,

	created() {
		this.padecimientoService = new PadecimientoService();
		this.tipopadecimientoService = new TipoPadecimientoService();
	},
	mounted() {
		this.padecimientoService.getPadecimientos().then(data => this.padecimientos = data);
		this.tipopadecimientoService.getTipoPadecimientos().then(data => this.tipopadecimientos = data);
	},
	methods: {
		openNew() {
			this.padecimiento = {};
			this.submitted = false;
			this.padecimientoDialog = true;
			this.padecimiento.activo=true;
		},
		hideDialog() {
			this.padecimientoDialog = false;
			this.submitted = false;
		},
		savePadecimiento() {
			this.submitted = true;
           
			if (this.padecimiento.nombre.trim()) {
				if (this.padecimiento.id_padecimiento>0) {
					// this.$set(this.padecimientos, this.findIndexById(this.padecimiento.id), this.padecimiento);
					this.tipopadecimientosPost.push({ 
						"id_padecimiento":this.padecimiento.id_padecimiento,
						"id_tipo_padecimiento":this.padecimiento.id_tipo_padecimiento.id_tipo_padecimiento,
						"nombre":this.padecimiento.nombre,
						"activo":this.padecimiento.activo
					}); 
					this.padecimientoService.updatePadecimientos(this.tipopadecimientosPost[0]).then(document.location.reload());
					this.$toast.add({severity:'success', summary: 'Successful', detail: 'Padecimiento actualizado', life: 3000});
					
					this.tipopadecimientosPost=[];
					 
				}
				else {
					// this.padecimiento.id = this.createId();
					// this.padecimientos.push(this.padecimiento);	
					this.tipopadecimientosPost.push({
						"id_padecimiento":this.padecimiento.id_padecimiento,
						"id_tipo_padecimiento":this.padecimiento.id_tipo_padecimiento.id_tipo_padecimiento,
						"nombre":this.padecimiento.nombre,
						"activo":this.padecimiento.activo
					});				
					this.padecimientoService.createPadecimientos(this.tipopadecimientosPost[0]).then();
					this.$toast.add({severity:'success', summary: 'Successful', detail: 'Padecimiento creado', life: 3000});
					this.tipopadecimientosPost=[];
					document.location.reload();				
				}

				this.padecimientoDialog = false;
				this.padecimiento = {};
			}
		},
		editPadecimiento(padecimiento) {
			this.padecimiento = {...padecimiento};
			this.padecimientoDialog = true;
			this.OptionTipoPadecimiento=this.padecimiento.id_tipo_padecimiento

			for(var i in this.tipopadecimientos){
				if(this.OptionTipoPadecimiento==this.tipopadecimientos[i].id_tipo_padecimiento){
					
					this.padecimiento.id_tipo_padecimiento = this.tipopadecimientos[i];
				}                  	
			} 
		},
		confirmDeletePadecimiento(padecimiento) {
			this.padecimiento = padecimiento;
			this.deletePadecimientoDialog = true;
		},
		deletePadecimiento() {
			// this.padecimientos = this.padecimientos.filter(val => val.id !== this.padecimiento.id);
			this.deletePadecimientoDialog = false;
			// this.padecimiento = {};
			this.tipopadecimientosPost.push({ 
						"id_padecimiento":this.padecimiento.id_padecimiento,
						"id_tipo_padecimiento":this.padecimiento.id_tipo_padecimiento.id_tipo_padecimiento,
						"nombre":this.padecimiento.nombre,
						"activo":0
					}); 
				this.padecimientoService.updatePadecimientos(this.tipopadecimientosPost[0]).then(document.location.reload());
			// this.padecimientoService.deletePadecimientos(this.padecimiento).then();
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Padecimiento eliminado', life: 3000});
		},
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.padecimientos.length; i++) {
				if (this.padecimientos[i].id === id) {
					index = i;
					break;
				}
			}

			return index;
		},
		createId() {
            let id = this.padecimientos.length + 1;
            /*
			var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			for ( var i = 0; i < 5; i++ ) {
				id += chars.charAt(Math.floor(Math.random() * chars.length));
            }
            */
			return id;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deletePadecimientosDialog = true;
		},
		deleteSelectedPadecimientos() {
			this.padecimientos = this.padecimientos.filter(val => !this.selectedPadecimientos.includes(val));
			this.deletePadecimientosDialog = false;
			this.selectedPadecimientos = null;
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Padecimientos eliminados', life: 3000});
		},
		turno(value){
			if(value ==1){
				return 'Activo'
			}else{
				return 'Inactivo'
			}
		},
		download : function() {
			this.padecimientoService.getPadecimientosExport().then(data => {
			this.PadecimientoExpor = data
				if(this.PadecimientoExpor != null){
				const data = XLSX.utils.json_to_sheet(this.PadecimientoExpor)
				const wb = XLSX.utils.book_new()
				XLSX.utils.book_append_sheet(wb, data, 'data')
				XLSX.writeFile(wb,'CatalogoPadecimiento.xlsx')
				}
			});
	
		},
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.padecimiento-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .padecimiento-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.padecimiento-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-activo {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-inactivo {
		background: #FFCDD2;
		color: #C63737;
	}
}
</style>
